.cardwrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 5%;
}
@media (max-width: 991px) {
  .cardwrapper {
    flex-direction: column;
    row-gap: 30px;
  }
}
.card {
  display: flex;
  padding: 30px;
  flex-direction: column;
  background: var(--humber-dark);
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15); */
  border-radius: 10px;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0 0 14px rgb(0 0 0 / 11%);
  border: 1px solid var(--humber-golden)
}
.card:hover .cardimg{
    /* height:120px */
    transform: scale(1.1);
}
.card:hover .text{
    font-weight: 600;
}

@media (max-width: 991px) {
  .card {
    width: 100%;
  }
}
.description {
  color: var(--humber-light);
    font-size: 16px;
    font-weight: 500;
    line-height: 1.1;
    padding: 0 10px;
    width: 230px;
}
@media (max-width: 991px) {
  .description {
    font-size: 18px;
  }
}
.text {
  font-size: 37px;
  font-weight: 500;
  transition: all .3s ease-in-out;
  /* padding: 30px 20px; */
}
@media (max-width: 991px) {
  /* .text:nth-child(1) {
        padding-top: 50px;
        color: red !important;
    } */
  .text {
    font-size: 20px;
  }
}

.guesstext {
  color: var(--humber-golden);
  font-size: 1.5rem;
  font-weight: 600;
}
.avatartext {
  color: var(--humber-golden);
  font-size: 1.5rem;
  font-weight: 600;
}
.triviatext {
  color: var(--humber-golden);
  font-size: 1.5rem;
  font-weight: 600;
}
.btn {
  border: none;
    
    background-color: #F46800;
    border-radius: 30px!important;
    outline: none;
    /* padding: 10px 50px; */
    cursor: pointer;
    color: var(--humber-black);
    /* border-radius: 11px; */
    /* font-size: 19px; */
    margin-top: 20px;


    opacity: 0.8;
    cursor: not-allowed;
    width: 150px;
    height: 40px;
    font-size: 1rem;
    border-radius: 5px;
    
    transition: all .3s ease-in-out;
    background:var(--gradient-primary)
  
}

.card :hover .btn{
    display:block!important;
    visibility: hidden!important;
    
}

.btn:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}


.cardimg{
  max-height: 67px;
  transition: all .5s ease-in-out;
  image-rendering: pixelated;
}

@media (max-width:600px){
  
  .cardimg{
    max-height:50px;
    transition: all .5s ease-in-out;
    image-rendering: pixelated;
  }
  .text{
    font-size: 1.3rem;
  }
  .description {
    font-size: 16px;
  }
}


.blink_me {
  animation: blinker 1s linear infinite;
  font-size: 16px;
  font-weight: 600;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}