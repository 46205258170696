.wrap {
    width: 22%;
    display: flex;
    flex-direction: column;
    padding: 0 0 20px 0;
    background: rgba(255, 255, 255, 0.86);
    box-shadow: 0px 0px 53px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
}
@media (max-width: 990px) {
    .wrap {
        width: 100%;
    }
}
.mybtn {
    height: 30px;
    width: 150px;
    margin: auto;
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    text-transform: capitalize;
    border-radius: 30px;
    font-size: 10px;
    outline: none;
}


.mypointbtn {
    background-color: white;
    border: 2px solid var(--humber-golden);
    color: #000;
}
.mypointbtn:hover {
    background: var(--gradient-primary);
    color: var(--humber-black);
    border: 2px solid var(--humber-golden);
}
.myrebtn {
    background: var(--gradient-primary);
    border: 2px solid var(--humber-light);
    color: var(--humber-black);
}
/* .myrebtn:hover {
    background-color: white;
    color: #F46800;
    border: 2px solid #F46800;
} */