.cardwrapper {
    display: flex; 
    column-gap: 5%;
    row-gap: 20px;
    /* width: 100%; */
    /* height: 400px; */
    margin-top: 20px;
    border: 5px groove black;
    flex-wrap: wrap;
}
@media (max-width: 991px){
    .cardwrapper {
        row-gap: 30px;
        height: 400px;
        overflow: scroll;
    }
}
.card {
    background: #FFFFFF;
box-shadow: 0px 0px 85px rgba(0, 0, 0, 0.05);
border-radius: 10px;
/* width: 30%; */
height: 180px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 0 20px;
text-align: center;
color: #444444;
cursor: pointer;
flex:0 0 19.2%
}
@media (max-width:1229px){
    .card{
        flex: 0 0 24.2%;
    }
}

@media (max-width:1143px){
    .card{
        flex: 0 0 32%;
    }
}
@media (max-width: 551px){
.card{
    -webkit-flex: 0 0 32%;
    -moz-box-flex: 0;
    -ms-flex: 0 0 32%;
    flex: 0 0 48%;
}
}

@media (max-width: 402px){
.card {
    -webkit-flex: 0 0 32%;
    -moz-box-flex: 0;
    -ms-flex: 0 0 32%;
    flex: 0 0 103%;
}
}
@media (max-width: 991px){
    .card {
        width: 100%;
    }
}
.cardtext {
    padding: 15px 0;
}
.activecard {
    font-weight: bold;
    background-color: #123873;
    color: #fff;
}