.editwrap {
    background: #F8F8F8;
    box-shadow: 0px 4px 78px 10px rgb(0 0 0 / 7%);
    width: 100%;
    margin: 10px auto;
    height: auto;
    position: relative;
    padding: 0px 40px 20px 40px;
    z-index: 1000;
}
@media (max-width: 991px){
    .editwrap {
        width: 100%;
    }
}
.formwrap {
    width: 100%;
    padding: 10px;
}
.label {
    color: #949494;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
}
input {
    width: 100%;
    height: 30px;
    padding: 25px;
    border-radius: 30px;
    outline: none;
    margin-bottom: 20px;
}

.btn {
    background-color: #2B9FDD;
    border: none;
    outline: none;
    padding: 10px 50px;
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    text-transform: capitalize;
    border-radius: 30px;
    font-size: 18px;
}
.content {
    color: #949494; font-size: 18px; padding: 10px 10px 20px 10px;
}
@media (max-width: 991px) {
    .content {
        font-size: 13px;
        padding: 20px 0;
    }
}
.cancel {
    display: flex;
    justify-content: flex-end;
    /* position: absolute;  */
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
}



.form_group{
   
        display: block;
        width: 100%;
        height: 49px;
        /* border-radius: 99px; */
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #8d9297!important;
        -webkit-appearance: none;
        -moz-appearance: none;
             appearance: none;
        border-radius: 12.25rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }



    .form_control {

    border: 2px solid var(--humber-golden);
    border-radius: 10px;
    height: 50px !important;
    padding: 0 .5rem;
    margin-bottom: 12px;
    display: block;
    color:var(--humber-light);
    background:transparent
    
        /* background: lightblue; */
        /* display: block;
        width: 100%;
        height: 55px;
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-clip: padding-box;
        border: 2px solid rgba(7, 48, 153, 0.29);
        -webkit-appearance: none;
        appearance: none;
        border-radius: 1rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; */
    }
    .form_button{
        background:var(--gradient-primary);
        border-radius: 20px;
        width: 100%;
        height: 50px;
        border: none;
        color: var(--humber-black);
        font-weight: 400;
        font-size: 16px;
    }

    .edit_profile{
        margin-top: -57px;
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 900;
        color: var(--humber-golden);
    }

    .label{
        color:var(--humber-light)
    }

   

    @media (max-width: 562px) {
        .gender{
            width:50%
        }
    }
    .selected_gender{
    background: rgb(15, 13, 95);
    color: white;
    }
