body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* zoom: 90%; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.root{

}
table {
  border-collapse: collapse!important;
}
table th, td {
  min-width: 150px!important;
}
@media (max-width: 750px) {
  table th, td {
    width: fit-content!important;
  min-width: 150px!important;
  }
}

.tha-wrapper{
/* margin: 0px 30px 0 30px !important; */
margin: 0px !important;
}

table{
  background: #fff !important;
}
 tbody{
/* background:#fff !important; */

}

